import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import ProtectedRoute from './content/pages/Login/ProtectedRoute';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Overview = Loader(lazy(() => import('src/content/overview')));

const Login = Loader(
  lazy(() => import('src/content/pages/Login/Login'))
);

const OrderControl = Loader(
  lazy(() => import('src/content/orderControl/index'))
);
const Transactions = Loader(
  lazy(() => import('src/content/Transactions'))
);

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/order-control" replace />
      },
      {
        path: 'login',
        element: <Login/>
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="order-control" replace />
      },
      {
        path: 'order-control',
        element: (
          <ProtectedRoute>
            <OrderControl />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: (
          <ProtectedRoute>
            <Transactions />
          </ProtectedRoute>
        )
      }
    ]
  }
];

export default routes;
