import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AUTH_API, ENV } from 'src/config';
import { useLocation } from 'react-router-dom';

interface AuthContextType {
  isAuthenticated: boolean;
  login: () => void
  logout: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [lastChecked, setLastChecked] = useState<number>(0)
  const location = useLocation();

  const logout = async () => {
    try {
      if(ENV === 'local') {
        setIsAuthenticated(false)
        setLastChecked(Date.now());
        return
      }
      await axios.post(`${AUTH_API}/logout`, {}, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      console.log('Logged out successfully');
      setIsAuthenticated(false)
    } catch (error) {
      console.error('Logout failed', error);
    }
  }

  const login = async () => {
    try {
      // Perform the login request here (if applicable)
      // After successful login, mark as authenticated
      setIsAuthenticated(true);
      setLastChecked(Date.now()); // Update the last check to avoid immediate verification
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  useEffect(() => {
      const checkAuth = async () => {
        console.log('im triggered')
        const now = Date.now();
        if (now - lastChecked < 5 * 60 * 1000) {
          console.log('no request, cached data instead')
          return;
        }
    
        if(ENV === 'local') {
          console.log('called')
          setIsAuthenticated(false);
          setLastChecked(Date.now());
          return
        }

        try {
          const response = await axios.get(`${AUTH_API}/verify`, {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
          });

          setIsAuthenticated(response.status === 200);
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setLastChecked(Date.now());
        }
      };

      checkAuth();
  }, [lastChecked, location.pathname]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login: login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};